
import { Vue, Component } from "vue-property-decorator";

type NavItem = {
  name: string;
  icon: string;
  to: string;
  custom?: boolean;
};

@Component({
  components: {
    AddFarmer: () => import("@/components/farmer/AddFarmer.vue"),
    AddDealer: () => import("@/components/dealer/AddAgroDealer.vue"),
  },
})
export default class HomeView extends Vue {
  public drawer = true;
  public mini = false;
  public items: NavItem[] = [
    { name: "Dashboard", icon: "mdi-home-city", to: "/home/dashboard" },
    { name: "Farmers", icon: "mdi-account", to: "/home/farmers" },
    {
      name: "Agro Dealers",
      icon: "mdi-account-group",
      to: "/home/agro-dealers",
    },
    {
      name: "Produce",
      icon: "mdi-truck-fast",
      to: "/home/produce",
    },
    {
      name: "Inputs",
      icon: "mdi-corn",
      to: "/home/inputs",
    },
    {
      name: "Orders",
      icon: "mdi-shopping",
      to: "/home/orders",
    },
    {
      name: "Enterprises",
      icon: "mdi-sprout",
      to: "/home/enterprises",
    },
    {
      name: "Cards",
      icon: "mdi-credit-card",
      to: "/home/cards",
    },
    {
      name: "Coffee",
      icon: "$coffee",
      to: "/home/coffee",
    },
    {
      name: "Settings",
      icon: "mdi-cog",
      to: "/home/settings",
    },
  ];

  // @farmer.Mutation(FarmerTypes.SET_ADD_FARMER_DIALOG)
  // public setAddFarmer!: (addFarmer: boolean) => void;

  // @agroDealer.Mutation(DealerTypes.SET_ADD_DEALER_DIALOG)
  // public setAddAgroDealer!: (addDealer: boolean) => void;

  // openAddFarmerDialog() {
  //   this.setAddFarmer(true);
  // }

  // openAddAgroDealerDialog() {
  //   this.setAddAgroDealer(true);
  // }
}
