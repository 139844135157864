
import {Vue, Component} from 'vue-property-decorator';
import traceabilityService from "@/services/traceability.service";
import {Traceability} from "@/store/modules/traceability/traceability.types";

@Component({})
export default class CoffeeFarmerView extends Vue {
  public loading = true;
  public traceability?: Traceability;

  async loadProfile(): Promise<void> {
    try {
      this.traceability = await traceabilityService.getTraceability(this.$route.params['id']);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.loadProfile();
  }
}
